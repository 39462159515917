import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { GuidePreview, Layout, SectionHeader } from '../components/common'
import { MetaData } from '../components/common/meta'

const Guides = ({ data, location }) => {
  const posts = data.allGhostPost.edges

  return (
    <>
      <MetaData location={location} />
      <Layout>
        <SectionHeader sectionTitle="Boba guides" />
        <div className="Guides__PreviewContainer">
          {posts.map(({ node }, index) => {
            return <GuidePreview key={node.id} post={node} large={index === 0}/>
          })}
        </div>
      </Layout>
    </>
  )
}

Guides.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired
}

export default Guides

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GuidesQuery {
    allGhostPost(
        sort: { order: DESC, fields: [published_at] },
        filter: { featured: { eq: true } }
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`
